import React from "react";
import Getid from "./Getid";
import Footer from "./footer";

const Homepage = () => {
  const handleClaimNowClick = () => {
    window.open("https://bit.ly/ggnowww", "_blank");
  };

  return (
    <div
      onClick={handleClaimNowClick}
      className="w-full md:h-[90vh] h-full bg-white items-center relative"
    >
      <div className="w-full h-full relative flex justify-center items-center">
        <img
          src="/singlepage4.jpg"
          alt="Hotel and Resort Promo"
          className="max-w-full max-h-full object-contain"
        />
      </div>

      <div className="pb-16">
        <Footer className="" handleClaimNowClick={handleClaimNowClick} />
      </div>
      <Getid handleClaimNowClick={handleClaimNowClick} />
    </div>
  );
};

export default Homepage;
